<template>
    <div>
        <section class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                    <div class="row justify-content-center mt-4">
                        <div class="col-12 col-sm-2 col-md-12 col-lg-12">
                            <div class="row justify-content-center">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 text-center">
                                    <router-link :to="{name:'proyectoslist'}" :class="DisabledProyectos ? 'btn btn-01 btn-sub btn-block disabled' : 'btn btn-01 btn-sub btn-block'" >
                                        <img src="../../images/spend/spend_bitacora.png" alt="Desprosoft"><br>
                                        <span class="text-btn small bold">Proyectos</span>
                                    </router-link>
                                </div>
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 text-center">
                                    <router-link :to="{name:'estadosfinancieros'}" class="btn btn-01 btn-sub btn-block" >
                                        <img src="../../images/finanzas/actualizar.png" alt="Desprosoft"><br>
                                        <span class="text-btn small bold">Análisis Financiero de Proyectos y Servicios</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            DisabledProyectos:true
        }
    },
    created() {
        let Paquetes=JSON.parse(sessionStorage.getItem('user')).listaPaquetesPrincipal;
        let IndexPaqueteProyectos=Paquetes.findIndex((element)=>element.Paquete=='Proyectos');
        this.DisabledProyectos=IndexPaqueteProyectos!=-1 ? false : true;
    },
}
</script>

<style>

</style>