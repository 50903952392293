<template>
    <div>
        <div class="form-group row">
            <div class="col-12 col-sm-12">
                <label for="">Hora</label>
                <h5 class="text-center" id="reloj"></h5>
            </div>
        </div>
        <div class="form-group row" v-if="ShowMap">
            <div class="col-12 col-sm-12">
                <Search :pOrigen="Origen" :oLatLng="oLatLng" :Input="false" :pIsDraggable="false"></Search>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12 col-sm-12">
                <label for="">Comentario</label>
                <textarea class="form-control" v-model="ObjCheckTrabajador.Comentario" cols="30" rows="10"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props:['poBtnSave','pFechaServidor'],
    data() {
        return {
            ObjCheckTrabajador:{
                IdCheck:0,
                IdTrabajador:0,
                TipoCheck:0,
                Comentario:'',
                Latitud:'',
                Longitud:'',
                Site:0
            },
            oLatLng:{},
            ShowMap:false,
            Origen:'',
            Intervalo:''
        }
    },
    methods: {
        Guardar(){
            this.poBtnSave.DisableBtn=true;
            // this.ObjCheckTrabajador.Latitud=position.coords.latitude;
                    // this.ObjCheckTrabajador.Longitud=position.coords.longitude;
                    let formData=new FormData();
                    formData.set('IdCheck',this.ObjCheckTrabajador.IdCheck);
                    formData.set('Estatus','');
                    formData.set('IdTrabajador',this.ObjCheckTrabajador.IdTrabajador);
                    formData.set('TipoCheck',this.ObjCheckTrabajador.TipoCheck);
                    formData.set('Comentario',this.ObjCheckTrabajador.Comentario);
                    formData.set('Site',this.ObjCheckTrabajador.Site);
                    formData.set('Latitud',this.oLatLng.Lat);
                    formData.set('Longitud',this.oLatLng.Lng);
                    formData.set('HoraExtra',0);
                    formData.set('CantidadHoraExtra',0);
                    this.$http.post('webapp/checkinsave',formData
                    ).then((res)=>{
                        $('#ModalForm').modal('hide');
                        this.bus.$emit('Lista_CheckIn');
                        this.$toast.success(res.data.message);
                        this.poBtnSave.DisableBtn=false;
                        this.$store.dispatch('ActualizarCheckIn');
                        // navigator.geolocation.clearWatch(WatchId);
                    });
            // if (navigator.geolocation) {
            //     let WatchId=navigator.geolocation.watchPosition((position)=>{
            //         this.ObjCheckTrabajador.Latitud=position.coords.latitude;
            //         this.ObjCheckTrabajador.Longitud=position.coords.longitude;
            //         let formData=new FormData();
            //         formData.set('IdCheck',this.ObjCheckTrabajador.IdCheck);
            //         formData.set('Estatus','');
            //         formData.set('IdTrabajador',this.ObjCheckTrabajador.IdTrabajador);
            //         formData.set('TipoCheck',this.ObjCheckTrabajador.TipoCheck);
            //         formData.set('Comentario',this.ObjCheckTrabajador.Comentario);
            //         formData.set('Latitud',this.ObjCheckTrabajador.Latitud);
            //         formData.set('Longitud',this.ObjCheckTrabajador.Longitud);
            //         formData.set('HoraExtra',0);
            //         formData.set('CantidadHoraExtra',0);
            //         this.$http.post('webapp/checkinsave',formData
            //         ).then((res)=>{
            //             $('#ModalForm').modal('hide');
            //             this.bus.$emit('Lista_CheckIn');
            //             this.$toast.success(res.data.message);
            //             this.poBtnSave.DisableBtn=false;
            //             this.$store.dispatch('ActualizarCheckIn');
            //             navigator.geolocation.clearWatch(WatchId);
            //         });
            //     },()=>{},{enableHighAccuracy: true,maximumAge:0,timeout: 10000,});
            // }
            // else{

            // }
        },
        Limpiar(){
            this.ObjCheckTrabajador={
                IdCheck:0,
                IdTrabajador:0,
                TipoCheck:0,
                Comentario:'',
                Latitud:'',
                Longitud:'',
                Site:0
            },
            this.oLatLng={};
            this.Origen='';
        }
    },
    created() {
        this.Intervalo=window.setInterval(()=> {
            // $('#reloj').html(moment().format('H:mm:ss'))
            let FechaProto=new Date(this.pFechaServidor);
            let Fecha = FechaProto.toLocaleString("es-MX", {
                hourCycle:"h24",
                hour:"2-digit",
                minute:"numeric",
                second:"numeric",
                timeZone: sessionStorage.getItem("ZonaHoraria")
            });
            $('#reloj').html(Fecha);
        }, 1000);
        this.bus.$off('Nuevo_CheckIn');
        this.bus.$on('Nuevo_CheckIn',(IdTrabajador,TipoCheck,IdCheck,Site)=>{
            this.bus.$off('Save_CheckIn');
            this.bus.$on('Save_CheckIn',()=>{
                this.Guardar();
            });
            this.Limpiar();
            let User=JSON.parse(sessionStorage.getItem("user"));
            switch (User.IdPerfil) {
                case 4:
                    this.Origen='Tecnico';
                    break;
                default:
                    this.Origen='Admin';
                    break;
            }
            this.ObjCheckTrabajador.IdTrabajador=IdTrabajador;
            this.ObjCheckTrabajador.TipoCheck=TipoCheck;
            this.ObjCheckTrabajador.IdCheck=IdCheck;
            this.ObjCheckTrabajador.Site=Site;
            this.ShowMap=false;
            navigator.geolocation.getCurrentPosition((position)=>{
                this.oLatLng.Lat=position.coords.latitude;
                this.oLatLng.Lng=position.coords.longitude;
            },()=>{},{enableHighAccuracy: true,maximumAge:0,timeout: 10000,});
            setTimeout(() => {
                this.ShowMap=true;
            }, 1000);
        });
    },
    beforeDestroy() {
        clearInterval(this.Intervalo);
    },
}
</script>

<style>

</style>